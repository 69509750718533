(function($) {
    if ($(".owl-header").length){
        $('.owl-header').owlCarousel({
            loop:true,
            items:1,
            dots:true,
            autoplay: true

        });
    }
    if ($(".owl-sostenibilitat").length){
        $('.owl-sostenibilitat').owlCarousel({
            loop:true,
            items:1,
            dots:true,
            dotsContainer: '#customDots',
            animateOut: 'fadeOut',
            touchDrag  : true,
     		mouseDrag  : false
        });
    }
    if ($("#year").length){
		    var selectedYear = $('#year').val();
		    console.log('selected year' + selectedYear);
		    $(".arxius").hide();
		    $(".show"+selectedYear).show();
		    $('#year').on('change', function(){
		    	var selectedYear = $(this).val(); 
		        $(".arxius").hide();
		        $(".show"+selectedYear).show();
		    });
		}
	$('.container-iframe')
	.click(function(){
			$(this).find('iframe').addClass('clicked')})
	.mouseleave(function(){
			$(this).find('iframe').removeClass('clicked')});
})(jQuery);

document.addEventListener('DOMContentLoaded', function() {
    const headers = document.querySelectorAll('.accordion-header');

    headers.forEach(header => {
        header.addEventListener('click', function() {
            const content = this.nextElementSibling;

            if (content.style.maxHeight) {
                // Collapse the content
                content.style.maxHeight = null;
            } else {
                // Collapse all other contents
                document.querySelectorAll('.accordion-content').forEach(c => {
                    c.style.maxHeight = null;
                });

                // Expand the clicked content
                content.style.maxHeight = content.scrollHeight + 'px';
            }
        });
    });
});
